<template>
  <div class="live-search-page">
    <div class="new-container mobile-hidden">
      <h1 class="page-title">
        {{ title }}
      </h1>
    </div>

    <div class="live-search-page-content">

      <div class="add-entity-block-header mobile-visible">
        <h1 class="page-title">{{ title }}</h1>
      </div>

      <template v-if="!searchResultsLoading">

        <div class="new-container"
             v-if="showFirstScreen">

          <div class="empty-results-block">
            <div class="image">
              <img src="@/assets/images/find-app-illustration.svg"
                   width="200"
                   height="194"
                   alt="Live search illustration"/>
            </div>
            <div class="empty-results-text">
              <div class="text-header">Live Search Tool</div>
              <div class="text-subheader">See in real-time which app is ranking for a specific keyword.
              </div>
            </div>

            <div class="page-intro-search-block">
              <template v-if="currentApp?.store?.key === 'APP_STORE'">
                <custom-dropdown :min-width="'182px'">
                  <template v-slot:header>
                    <span class="capitalized1">{{ liveSearchDevice }}</span>
                  </template>
                  <template v-slot:content>
                    <div class="select-options">
                      <div class="option"
                           v-if="liveSearchDevice !== 'iPhone'"
                           @click="liveSearchDeviceChanged('iPhone')">iPhone
                      </div>
                      <div class="option"
                           v-if="liveSearchDevice !== 'iPad'"
                           @click="liveSearchDeviceChanged('iPad')">iPad
                      </div>
                    </div>
                  </template>
                </custom-dropdown>
              </template>

              <div class="search-input-wrap">
                <form>
                  <label>
                    <input type="text"
                           class="search-input"
                           @input.prevent
                           @keydown.prevent.enter="inputSearchHandler"
                           placeholder="Search keyword..."
                           ref="liveSearchInput"
                           autofocus
                           v-model="currentKeyword">
                  </label>
                </form>
                <svg-icon class="search-icon"
                          icon="search-solid"
                          @click="inputSearchHandler"/>
              </div>
            </div>

          </div>
        </div>

        <div v-else
             class="live-search-results">
          <div class="new-container">
            <div class="search-block">
              <div class="device-dropdown-mobile mobile-visible"
                   v-if="currentApp?.store?.key === 'APP_STORE'">
                <custom-dropdown :min-width="'182px'">
                  <template v-slot:header>
                    <span class="capitalized1">{{ liveSearchDevice }}</span>
                  </template>
                  <template v-slot:content>
                    <div class="select-options">
                      <div class="option"
                           v-if="liveSearchDevice !== 'iPhone'"
                           @click="liveSearchDeviceChanged('iPhone')">iPhone
                      </div>
                      <div class="option"
                           v-if="liveSearchDevice !== 'iPad'"
                           @click="liveSearchDeviceChanged('iPad')">iPad
                      </div>
                    </div>
                  </template>
                </custom-dropdown>
              </div>

              <div class="display-flex f-align-center w100p">
                <div class="search-input-wrap">
                  <form>
                    <label>
                      <input type="text"
                             class="search-input"
                             @input.prevent
                             @keydown.prevent.enter="inputSearchHandler"
                             placeholder="Search keyword..."
                             ref="liveSearchInput"
                             autofocus
                             v-model="currentKeyword">
                    </label>
                  </form>
                  <svg-icon class="search-icon"
                            @click="inputSearchHandler"
                            icon="search-solid"/>
                </div>

                <span class="store_query_correct_hint mr-10" v-if="querySugg">Results for: <b>{{querySugg}}</b></span>
              </div>

              <order-radaso-button class="order-radaso-wrap" :pre-selected="['keywords-research']" />

              <div class="device-buttons-block mobile-hidden"
                   v-if="currentApp?.store?.key === 'APP_STORE'">
                <button type="button"
                        class="device-btn"
                        :class="{active: liveSearchDevice === 'iPhone'}"
                        @click="liveSearchDeviceChanged('iPhone')">iPhone
                </button>
                <button type="button"
                        class="device-btn"
                        :class="{active: liveSearchDevice === 'iPad'}"
                        @click="liveSearchDeviceChanged('iPad')">iPad
                </button>
              </div>
            </div>

            <template v-if="autoSuggestedKeywords?.length > 0">
              <div class="search-suggests-block">
                <div class="chips-list">
                  <custom-chips v-for="(chip, idx) in autoSuggestedKeywords"
                                :selectable="true"
                                :is-selected="false"
                                :is-frozen="chip.frozen"
                                :key="idx"
                                :is-inactive="true"
                                @chip-added="suggestClicked(chip.name)"
                                :amount="+chip?.sap">{{ chip.name }}
                  </custom-chips>
                  <div class="chips-item-wrap">
                    <router-link to="/search-terms/keywords-autosuggest"
                                 tag="div"
                                 class="more">
                      More Suggests
                    </router-link>
                  </div>
                </div>
              </div>
            </template>

            <div class="live-search-metrics">
              <div class="metrics-grid tablet-hidden mobile-hidden">
                <div class="metrics-grid-wrap">
                  <div class="metrics-grid-item">
                    <div class="metric-value"
                         :class="{skeleton: topSkeletonBoxesShown}">
                      {{ totalAppsCount }}
                    </div>
                    <div class="metric-title">
                      <span>Total Apps</span>
                    </div>
                  </div>
                </div>
                <div class="metrics-grid-wrap">
                  <div class="metrics-grid-item">
                    <div class="metric-value"
                         :class="{skeleton: topSkeletonBoxesShown}">
                      {{ keywordCounts.popularity }}
                    </div>
                    <div class="metric-title">
                      <span>Popularity</span>
                      <svg-icon v-if="currentApp?.store?.key === 'APP_STORE'"
                                class="metric-chart-icon"
                                @click="popularityChartClicked"
                                icon="chart"/>
                    </div>
                  </div>
                </div>
                <div class="metrics-grid-wrap">
                  <div class="metrics-grid-item">
                    <div class="metric-value"
                         :class="{skeleton: topSkeletonBoxesShown}">
                      {{ keywordCounts.impression }}
                    </div>
                    <div class="metric-title">
                      <span>Daily Impr.</span>
                    </div>
                  </div>
                </div>
                <div class="metrics-grid-wrap">
                  <div class="metrics-grid-item">
                    <div class="metric-value"
                         :class="{skeleton: topSkeletonBoxesShown}">
                      {{ keywordCounts.app_rank }}
                    </div>
                    <div class="metric-title">
                      <span>Your App Ranking</span>
                    </div>
                  </div>
                </div>
                <div class="metrics-grid-wrap">
                  <div class="metrics-grid-item">
                    <div class="metric-value"
                         :class="{skeleton: topSkeletonBoxesShown}">
                      {{ keywordCounts.competitors }}
                    </div>
                    <div class="metric-title">
                      <span>Сompetitors (Top 10)</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="average-metrics-top top-block desktop-hidden">
                <template v-if="topSkeletonBoxesShown">
                  <preloader/>
                </template>
                <template v-else>
                  <div class="table-metrics">
                    <div class="basic-table">
                      <div class="table-row">
                        <div class="left-side">Top Apps</div>
                        <div class="right-side">
                          {{ totalAppsCount }}
                        </div>
                      </div>

                      <div class="table-row">
                        <div class="left-side">Popularity</div>
                        <div class="right-side">{{ keywordCounts.popularity }}</div>
                      </div>

                      <div class="table-row">
                        <div class="left-side">Daily Impr.</div>
                        <div class="right-side">{{ keywordCounts.impression }}</div>
                      </div>

                      <div class="table-row">
                        <div class="left-side">Your App Ranking</div>
                        <div class="right-side">{{ keywordCounts.app_rank }}</div>
                      </div>

                      <div class="table-row">
                        <div class="left-side">Сompetitors (Top 10)</div>
                        <div class="right-side">{{ keywordCounts.competitors }}</div>
                      </div>

                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="live-search-table">
            <template v-if="tableSkeletonBoxesShown">
              <basic-data-table :headers="tableColumns"
                                :items="dummyTableData"
                                :fixed-width="true"
                                :show-actions="false">

                <template v-slot:items="{item}">
                  <td v-for="headerItem in tableColumns"
                      :style="{width: headerItem.width}"
                      :class="[headerItem.align]">
                    <div class="inner-content">
                      <template v-if="headerItem?.value !== 'tools'">
                        <template v-for="(tdItem, tdIndex) in item"
                                  :key="tdIndex">

                          <template v-if="tdIndex === headerItem.value">
                            <template v-if="headerItem?.value === 'title'">
                              <div class="skeleton long-skeleton-box"></div>
                            </template>

                            <template v-else-if="headerItem?.value === 'developer'">
                              <div class="skeleton medium-skeleton-box"></div>
                            </template>

                            <template v-else-if="headerItem?.value === 'categories'">
                              <div class="skeleton medium-skeleton-box"></div>
                            </template>

                            <template v-else-if="headerItem?.value === 'rating'">
                              <div class="skeleton short-skeleton-box"></div>
                            </template>

                            <template v-else-if="headerItem?.value === 'votes'">
                              <div class="skeleton short-skeleton-box"></div>
                            </template>

                            <template v-else>
                              <div class="skeleton short-skeleton-box"></div>
                            </template>

                          </template>

                        </template>
                      </template>
                      <template v-if="headerItem?.value === 'tools'">
                        <div class="tools">
                          <div class="skeleton medium-skeleton-box"></div>
                        </div>
                      </template>
                    </div>
                  </td>
                </template>
              </basic-data-table>
            </template>
            <template v-else>
              <translate-tool v-if="liveSearchResults?.results?.length > 0"
                              :active="translateIsActive"
                              class="left-tooltip"
                              @translate-clicked="translateClickHandler">
                <template v-slot:text>
                  Translate all applications into English
                </template>
              </translate-tool>
              <basic-data-table v-if="liveSearchResults?.results?.length > 0"
                                :headers="tableColumns"
                                :items="liveSearchResults?.results"
                                :fixed-width="true"
                                :show-actions="false">

                <template v-slot:items="{item}">

                  <td :style="tableStyleCombined(tableHeaderItem('position'))"
                      :class="tableClassCombined(tableHeaderItem('position'))">
                    <div class="inner-content">
                      <div class="position">
                        <span v-if="!item.is_paid">#{{ item.positionIndex }}</span>
                      </div>
                    </div>
                  </td>

                  <td :style="tableStyleCombined(tableHeaderItem('title'))"
                      :class="tableClassCombined(tableHeaderItem('title'))">
                    <div class="inner-content"
                         :class="[{'cursor-pointer': item?.type === 'apps', 'custom-css-tooltip': item?.type === 'apps'}]"
                         data-title="Open detailed info"
                         v-on="item?.type === 'apps' ? {click: () => appClickHandler(item)}: {}">

                      <template v-if="item.type === 'editorial-items' || item.type === 'subscription'">
                        <img :src="item.data.icon"
                             v-if="item?.data?.icon"
                             width="32"
                             height="32"
                             class="app-logo"
                        >
                        <div v-else class="empty-logo"></div>
                      </template>
                      <template v-else-if="item.type !== 'editorial-items' && item.type !== 'subscription' && item.type !== 'apps' && item.type !== 'preorder'">
                        <img :src="cropImg(item.data.icon, '32x32', item?.data?.store_key)"
                             v-if="item?.data?.icon"
                             width="32"
                             height="32"
                             class="app-logo"
                        >
                        <div v-else class="empty-logo"></div>
                      </template>
                      <template v-else>
                        <img :src="cropImg(item.data.logo, '32x32', item?.data?.store_key)"
                             width="32"
                             height="32"
                             class="app-logo"
                        >
                      </template>

                      <div class="text-overflow"
                           :style="tableStyleCombined(tableHeaderItem('title'))">
                        <div v-if="item.type !== 'apps'"
                             class="type">{{ constructAppType(item.type) }}
                        </div>
                        <div class="item-title">
                          <a class="app-link mobile-hidden"
                             v-if="item?.data?.link"
                             :href="item.data.link"
                             target="_blank">
                            <svg-icon icon="link-icon" class="link-icon"/>
                          </a>
                          <div class="title display-flex">
                            <div>
                              {{ item.data.title }}
                              <div class="translated-block">
                                {{ item.translate }}
                              </div>
                            </div>
                            <div class="ads_mark ml-10" v-if="item.is_paid">Ad</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td :style="tableStyleCombined(tableHeaderItem('tools'))"
                      :class="tableClassCombined(tableHeaderItem('tools'))">
                    <div class="inner-content"
                         v-if="item.type === 'apps' || item.type === 'preorder'">

                      <div class="tools">
                        <change-competitor-state :item="item"
                                                 :current-app-id="currentApp?.id"
                                                 @competitor-changed="competitorChanged"
                        />
                      </div>
                    </div>
                  </td>

                  <td :style="tableStyleCombined(tableHeaderItem('developer'))"
                      :class="tableClassCombined(tableHeaderItem('developer'))">
                    <div class="inner-content"
                         v-if="item.type === 'apps' || item.type === 'in-apps' || item.type === 'preorder'">
                      <div class="text-overflow"
                           :style="tableStyleCombined(tableHeaderItem('developer'))">
                        {{ item.data.developer_name }}
                      </div>
                    </div>
                  </td>

                  <td :style="tableStyleCombined(tableHeaderItem('categories'))"
                      :class="tableClassCombined(tableHeaderItem('categories'))">
                    <div class="inner-content"
                         v-if="item.type === 'apps' || item.type === 'in-apps' || item.type === 'preorder'">
                      <div class="text-overflow"
                           :style="tableStyleCombined(tableHeaderItem('categories'))">
                        {{ item.data.categories.join(', ') }}
                      </div>
                    </div>
                  </td>

                  <td :style="tableStyleCombined(tableHeaderItem('rating'))"
                      :class="tableClassCombined(tableHeaderItem('rating'))">
                    <div class="inner-content"
                         v-if="item.type === 'apps' || item.type === 'preorder'">
                      <svg-icon icon="star" class="rating-icon"/>
                      <strong class="rating">{{ item.data.rating_num }}</strong>
                    </div>
                  </td>

                  <td :style="tableStyleCombined(tableHeaderItem('votes'))"
                      :class="tableClassCombined(tableHeaderItem('votes'))">
                    <div class="inner-content"
                         v-if="item.type === 'apps' || item.type === 'preorder'">
                      {{ item.data.votes_num ? minifyDigitsToLetters(item.data.votes_num) : '' }}
                    </div>
                  </td>
                </template>
              </basic-data-table>
            </template>

            <div class="load-more-btn-wrap"
                 v-if="showLoadMoreBtn && !tableSkeletonBoxesShown">

              <template v-if="!showLoadMorePreloader">
                <base-button height="40px"
                             width="160px"
                             font-size="15px"
                             border-radius="8px"
                             @click="loadMoreClickHandler($event)">
                  Load More
                </base-button>
              </template>
              <template v-else>
                <preloader height="40px"/>
              </template>
            </div>

          </div>

          <div class="new-container">
            <template v-if="skeletonBoxesShown">
              <preloader/>
            </template>
            <template v-else>
              <div class="average-metrics-top">
                <template v-if="keywordsTopCategory">
                  <h3 class="block-subheader">Top 10 Category Distribution</h3>
                  <div class="progress-metrics">
                    <div class="progress-metric-item"
                         v-for="metric in keywordsTopCategory">
                      <div class="metric-header">
                        <div class="left-side">{{ metric?.title }}</div>
                        <div class="right-side">{{ metric?.distribution_percent }}%</div>
                      </div>
                      <progress-bar :width="metric?.distribution_percent + '%'"/>
                    </div>
                  </div>
                </template>

                <h3 class="block-subheader">Top 10 Apps Insights</h3>
                <div class="table-metrics">
                  <div class="basic-table">
                    <div class="table-row">
                      <div class="left-side">Number of competitors (Top 10)<span
                            class="mobile-visible">:&nbsp;<strong>{{
                        keywordsAverageMetrics.competitors
                        }}</strong></span>
                      </div>
                      <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.competitors }}</div>
                    </div>

                    <div class="table-row">
                      <div class="left-side">Average rating<span class="mobile-visible">:&nbsp;<strong>{{
                        keywordsAverageMetrics.avg_rating
                        }}</strong></span>
                      </div>
                      <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.avg_rating }}</div>
                    </div>

                    <div class="table-row">
                      <div class="left-side">Average number of votes<span class="mobile-visible">:&nbsp;<strong>{{
                        keywordsAverageMetrics.avg_votes
                        }}</strong></span>
                      </div>
                      <div class="right-side mobile-hidden">{{
                        minifyDigitsToLetters(keywordsAverageMetrics.avg_votes)
                        }}
                      </div>
                    </div>

                    <div class="table-row">
                      <div class="left-side">Average age of apps (Top 10)<span class="mobile-visible">:&nbsp;<strong>{{
                        keywordsAverageMetrics.avg_age_in_days
                        }}</strong></span>
                      </div>
                      <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.avg_age_in_days }}</div>
                    </div>

                    <div class="table-row">
                      <div class="left-side">Percentage of free apps<span class="mobile-visible">:&nbsp;<strong>{{
                        keywordsAverageMetrics.free_percent
                        }}%</strong></span>
                      </div>
                      <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.free_percent }}%</div>
                    </div>

                    <div class="table-row">
                      <div class="left-side">Average price of paid ($)<span class="mobile-visible">:&nbsp;<strong>{{
                        keywordsAverageMetrics.avg_price
                        }}</strong></span>
                      </div>
                      <div class="right-side mobile-hidden">{{ keywordsAverageMetrics.avg_price }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <!--          <div class="new-container">-->
          <!--            <the-improvement-block/>-->
          <!--          </div>-->

        </div>

      </template>
      <template v-else>
        <progress-indicator :loaded="searchResultsLoading">{{ loaderText }}</progress-indicator>
      </template>
    </div>

    <custom-modal :opened="showTableChartModal"
                  :max-width="728"
                  class-name="popularity-chart-modal"
                  @closeModal="closeModal('showTableChartModal')">
      <div class="modal-heading small-heading">
        <div class="left-side">
          <h3>Popularity trend for <span class="blue">{{ currentKeyword }}</span> in 30 days</h3>
        </div>
      </div>

      <div class="chart-block">
        <preloader-table-small v-if="showPopularChartLoader"
                               :loader-size="'40px'"></preloader-table-small>

        <template v-else>
          <vue-highcharts
                v-if="popularityChartResultsLength > 0"
                type="chart"
                :options="getPopularData()"
                :redrawOnUpdate="true"
                :oneToOneUpdate="false"
                :animateOnUpdate="true"/>

          <div v-else>No Data</div>
        </template>
      </div>
    </custom-modal>

    <custom-modal :opened="showAddAppModal"
                  :max-width="420"
                  @closeModal="closeModal('showAddAppModal')">
      <div class="modal-message">Add to My Apps?</div>
      <template v-slot:footer-action>
        <div class="btn-block action-btns">
          <base-button @click="addAppConfirm">Yes</base-button>

          <base-button mode="outline"
                       height="40px"
                       width="180px"
                       @click="closeModal('showAddAppModal')">
            Cancel
          </base-button>
        </div>
      </template>
    </custom-modal>

    <details-modal v-if="showAppDetailsModal"
                   :opened="showAppDetailsModal"
                   :app-data="clickedItem"
                   @competitor-changed="modalCompetitorChanged"
                   @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TheHeader from '@/components/TheHeader/index';
import BasicSearchInput from '@/components/UI/BasicSearchInput/index';
import ProgressBar from '@/components/UI/ProgressBar/index';
import CustomChips from "@/components/Forms/CustomChips";
import {httpRequest} from "@/api";
import {liveSearchTableConfig} from "@/configs/page-tables-configs/live-search";
import CustomDropdown from "@/components/UI/CustomDropdown/index";
import SvgIcon from "@/components/UI/SvgIcon";
import {keywordsPopularChartOptions} from "@/configs/keywords-popular-charts/keywords-popular-charts";
import {cropImage, deleteCookie, formatDate, getCookie, setCookie} from "@/utils/utils";
import PreloaderTableSmall from '@/components/UI/PreloaderTableSmall/index';
import BasicDataTable from "@/components/DataTables/BasicDataTable";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import Preloader from "@/components/UI/Preloader";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import {UserAppsArrayCreator} from "@/utils/factories";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import ChangeCompetitorState from "@/components/Billets/ChangeCompetitorState/index.vue";
import competitorState from "@/api/modules/competitor-state";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: 'RankingKeywords',
  components: {
    OrderRadasoButton,
    ChangeCompetitorState,
    TranslateTool,
    SvgIcon,
    'the-header': TheHeader,
    'basic-search-input': BasicSearchInput,
    'custom-chips': CustomChips,
    'progress-bar': ProgressBar,
    'preloader-table-small': PreloaderTableSmall,
    'preloader': Preloader,
    'custom-dropdown': CustomDropdown,
    'basic-data-table': BasicDataTable,
    'progress-indicator': ProgressIndicator,
    DetailsModal,
  },
  data() {
    return {
      tableKey: 0,
      title: '',
      loaderText: '',
      searchResults: [],
      autoSuggestedKeywords: [],
      currentKeyword: '',
      showTableChartModal: false,
      showPopularChartLoader: false,
      liveSearchDevice: 'iPhone',
      liveSearchPage: 1,
      tableColumns: liveSearchTableConfig.headers,
      currentKeywordCountryCode: 'US',
      currentKeywordCountryId: null,
      searchResultsLoading: false,
      skeletonBoxesShown: true,
      tableSkeletonBoxesShown: true,
      topSkeletonBoxesShown: true,
      showFirstScreen: true,
      keywordCounts: {},
      keywordsTopCategory: {},
      keywordsAverageMetrics: {},
      liveSearchResultsSecondary: [],
      myAppsExtractedIds: [],
      showAddAppModal: false,
      addAppId: null,
      dummyTableData: liveSearchTableConfig.dummyTableData,
      currentLiveSearchPage: 0,
      showLoadMoreBtn: false,
      querySugg: null,
      initialLoad: true,
      tableIndex: 1,
      showLoadMorePreloader: false,
      showAppDetailsModal: false,
      clickedItem: {},
      translateIsActive: false,
    }
  },
  async mounted() {
    this.liveSearchDevice = this.currentApp?.store?.key === 'APP_STORE' ? 'iPhone' : 'mobile';
    if (getCookie('liveSearchInput')) {
      this.currentKeyword = getCookie('liveSearchInput');
      await this.resetData();
      this.initialLoad = false;
    }

    if (this.$refs.liveSearchInput) {
      this.$refs.liveSearchInput.focus();
    }

    if (!this.myApps) {
      await this.fetchMyApps();
    }
  },
  methods: {
    async addAppModalHandler(id) {
      this.openModal('showAddAppModal');
      this.addAppId = id;
    },
    async addAppConfirm() {
      await this.addAppHandler(this.addAppId);
    },
    async addAppHandler(id) {
      let formData = new FormData();
      formData.append('application_id', id);
      formData.append('country_code', this.currentCountry?.code);

      const tempLiveSearchResults = this.liveSearchResults.slice(0);
      const addedIndex = tempLiveSearchResults.findIndex(item => item.id === id);
      tempLiveSearchResults[addedIndex].isLoading = true;
      this.liveSearchResults = [...tempLiveSearchResults];

      const result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS, formData);

      if (result.hasOwnProperty('applications')) {
        tempLiveSearchResults[addedIndex].isAdded = true;
      }

      tempLiveSearchResults[addedIndex].isLoading = false;
      this.liveSearchResults = [...tempLiveSearchResults];
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.closeModal('showAddAppModal');
    },
    async suggestClicked(keyword) {
      this.currentKeyword = keyword;
      await this.inputSearchHandler();
    },
    async competitorChanged(newType, id, oldType = null) {
      let competitorsCurrAmount = await competitorState.competitorChanged(
          newType,
          id,
          oldType,
          this.currentCountry?.id,
      );

      if (competitorsCurrAmount !== false) {
        let newAmount = +this.keywordsAverageMetrics.competitors + competitorsCurrAmount;
        newAmount = Math.max(newAmount, 0);
        this.keywordsAverageMetrics.competitors = newAmount;
        this.keywordsAverageMetrics = {...this.keywordsAverageMetrics};

        this.keywordCounts.competitors = newAmount;
        this.keywordCounts = {...this.keywordCounts};
      }
    },
    async popularityChartClicked() {
      this.openModal('showTableChartModal');

      await this.fetchPopularityChartResults();
    },
    minifyDigitsToLetters(val) {
      if (val >= 1e3 || val <= -1e3) {
        const units = ["K", "M", "B", "T"];
        let unit = Math.floor(((val).toFixed(0).length - 1) / 3) * 3
        let num = (val / ('1e' + unit)).toFixed(1)
        let unitName = units[Math.floor(unit / 3) - 1]
        return Math.abs(num) + unitName;
      }

      return Math.abs(val);
    },
    async fetchPopularityChartResults(period) {
      const store = this.currentApp?.store?.key ?? 'APP_STORE';
      this.showPopularChartLoader = true;

      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.keywordsTracking.POPULARITY_DYNAMICS
          + '?keyword=' + this.currentKeyword?.toLowerCase()
          + '&country_code=' + this.currentCountry?.code
          + '&period=week'
          + '&store_key=' + store;

      const result = await httpRequest('GET', url);
      this.$store.dispatch('keywordsTracking/SET_POPULARITY_CHART_RESULTS', result?.popularity_dynamics);
      this.showPopularChartLoader = false;
    },
    async inputSearchHandler() {
      if (this.currentKeyword.length === 0) {
        return;
      }

      const cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;
      const days = 0.007;
      setCookie('liveSearchInput', this.currentKeyword, cookieDomain, days);

      this.tableIndex = 1;
      await this.resetData();
    },
    async resetData() {
      this.autoSuggestedKeywords = [];
      this.currentLiveSearchPage = 0;
      this.showFirstScreen = false;
      this.tableSkeletonBoxesShown = true;
      this.topSkeletonBoxesShown = true;
      this.skeletonBoxesShown = true;
      this.keywordCounts = {};
      if (!this.myApps) {
        await this.fetchMyApps();
      }
      await this.fillLiveSearchArrays();
      await this.fetchCompetitors();
      this.tableSkeletonBoxesShown = false;
      await this.fetchSuggestions();
      await this.sendPromisesBundle();
      this.skeletonBoxesShown = false;
    },
    getPopularData() {
      let popularityArray = [];
      for (const key in this.popularityChartResults) {
        popularityArray.push({
          date: formatDate(key, 'month-day-year'),
          rankData: this.popularityChartResults[key]
        });
      }

      let dates = [];
      let rank = [];
      for (const key in this.popularityChartResults) {
        dates.push(formatDate(key, 'month-day-year'));
        rank.push(this.popularityChartResults[key]);
      }

      const rankingData = popularityArray.sort(function (a, b) {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateA < dateB ? -1 : 1;
      });

      let self = this;

      return {
        ...keywordsPopularChartOptions,
        xAxis: {
          categories: rankingData.map(item => item.date),
          labels: {
            // step: steps,
            // step: 1,
            // align: 'left',
            // useHTML: true,
            rotation: -45,
            style: {
              color: '#a2aabe',
              fontSize: '14px',
              textOverflow: 'none',
              whiteSpace: 'nowrap',
            },
            formatter: function () {
              if (rankingData?.length === 12) {
                // if (!this.isFirst && !this.isLast) {
                return this.value;
                // }
              } else if (rankingData?.length > 4 && rankingData?.length < 12) {
                return this.value;
              } else {
                return this.value;
              }
            },
          }
        },
        yAxis: {
          title: false,
          labels: {
            style: {
              color: '#a2aabe',
              fontSize: 14
            },
            formatter: function () {
              return this.value;
            },
          },
          gridLineDashStyle: 'longdash',
        },
        series: [{
          data: rankingData.map(item => item.rankData.SAP),
          lineWidth: 3,
          pointHitRadius: 10,
          marker: {
            states: {
              hover: {
                fillColor: 'white',
                radius: 3,
                lineWidth: 1,
                lineColor: '#7688ff',
              }
            }
          },
          // enableMouseTracking: false
        }],
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
            }],
            yAxes: [{
              stacked: true,
              gridLines: {
                borderDash: [8, 4],
                color: "#f0f3fd",
                zeroLineColor: 'transparent',
                drawBorder: false,
                drawOnChartArea: true,
              },
              type: 'linear',
            }]
          },
        },
        tooltip: {
          enabled: true,
          width: 100,
          distance: 20,
          padding: 8,
          outside: false,
          useHTML: true,
          backgroundColor: '#fff',
          borderColor: '#d7dde7',
          borderRadius: 4,
          shadow: false,
          shape: "square",
          hideDelay: 15,
          formatter: function () {
            let sapDifference = null;
            const category = this.point.category;
            for (const key in self.popularityChartResults) {
              if (category === formatDate(key, 'month-day-year')) {
                sapDifference = self.popularityChartResults[key]?.difference;
                break;
              }
            }

            const differenceColor = sapDifference > 0 ? 'green' : 'red';
            const differenceRenderValue = sapDifference > 0 ? '+' + sapDifference : sapDifference;
            let differenceBlock = '';

            if (sapDifference !== 0 && sapDifference !== null) {
              differenceBlock = `<span class="difference ${differenceColor}">${differenceRenderValue}</span>`;
            }

            return `<div class="tooltip-inner-container">${this.point.category}
                      <br />
                      <span class="rank">Popularity: <strong>${this.point.y}</strong>
                      ${differenceBlock}
                      </span>
                    </div>`
          }
        },
      };
    },
    async liveSearchDeviceChanged(device) {
      this.liveSearchDevice = device;
      this.currentLiveSearchPage = 0;

      if (!this.showFirstScreen) {
        await this.inputSearchHandler();
      }
    },
    extractAppsIds() {
      this.myAppsExtractedIds = [];
      for (const key in this.myApps) {
        this.myAppsExtractedIds.push(this.myApps[key]?.id);
      }
    },
    async fetchTopCounts(currentAppId) {
      this.topSkeletonBoxesShown = true;
      const keywordCounts = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL
          + this.$ApiUrls.liveSearch.KEYWORDS_COUNTS
          + '?app_id=' + currentAppId
          + '&country_code=' + this.currentCountry?.code
          + '&device=' + this.liveSearchDevice?.toLowerCase()
          + '&keyword=' + this.currentKeyword?.toLowerCase()
      );

      if (keywordCounts) {
        let totalApps = keywordCounts?.total_apps ? keywordCounts.total_apps?.toLocaleString('ru-RU', {maximumFractionDigits: 0}) : 0;
        if (totalApps === 0 || this.currentApp.store.key === 'APP_STORE') {
          totalApps = this.keywordCounts.total_apps_from_live;
        }
        let appRank = this.keywordCounts?.app_rank_from_live ? this.keywordCounts.app_rank_from_live.toFixed(0) : 0;
        this.keywordCounts = {
          total_apps: totalApps,
          popularity: keywordCounts?.popularity ? keywordCounts.popularity?.toLocaleString('ru-RU', {maximumFractionDigits: 0}) : 0,
          impression: keywordCounts?.impression ? keywordCounts.impression?.toLocaleString('ru-RU', {maximumFractionDigits: 0}) : 0,
          app_rank: appRank,
          competitors: keywordCounts?.competitors ? keywordCounts.competitors?.toLocaleString('ru-RU', {maximumFractionDigits: 0}) : 0,
        }
      }

      this.topSkeletonBoxesShown = false;
    },
    async sendPromisesBundle() {
      const currentAppId = getCookie('currentAppId');
      if (!currentAppId || currentAppId === 'undefined') {
        return;
      }

      await this.fetchTopCounts(currentAppId);

      const promiseUrls = [
        {
          url: process.env.VUE_APP_API_URL
              + this.$ApiUrls.liveSearch.KEYWORDS_TOP_AVERAGE_METRICS
              + '?country_code=' + this.currentCountry?.code
              + '&app_id=' + currentAppId
              + '&device=' + this.liveSearchDevice?.toLowerCase()
              + '&keyword=' + this.currentKeyword?.toLowerCase(),
          method: 'GET',
        },
        {
          url: process.env.VUE_APP_API_URL
              + this.$ApiUrls.liveSearch.KEYWORDS_TOP_CATEGORY
              + '?country_code=' + this.currentCountry?.code
              + '&device=' + this.liveSearchDevice?.toLowerCase()
              + '&app_id=' + this.currentApp?.id
              + '&keyword=' + this.currentKeyword?.toLowerCase(),
          method: 'GET',
        },
      ];

      await this.$store.dispatch('FETCH_PROMISE_ALL_SETTLED', promiseUrls);
      const keywordsAverageMetrics = this.promiseSettledData?.data[0]?.value;
      const keywordsTopCategory = this.promiseSettledData?.data[1]?.value?.app_categories;

      if (this.promiseSettledData.success) {
        this.keywordsTopCategory = null;
        if (keywordsTopCategory) {
          const sortedTopCategories = keywordsTopCategory?.sort((a, b) => (a?.distribution_percent < b?.distribution_percent) ? 1 : -1);
          this.keywordsTopCategory = {...sortedTopCategories};
        }

        if (keywordsAverageMetrics) {
          const appAge = keywordsAverageMetrics?.avg_age_in_days ?? 0;
          let avgAppAgeStr = '';

          if (appAge > 0) {
            const years = Math.abs(Math.floor(appAge / 365));
            const daysDiff = Math.abs(Math.floor(appAge - (years * 365)));
            let months = Math.abs(Math.floor(daysDiff / 30));
            if (months === 12) {
              months = 11;
            }
            const yearsStr = years === 0 ? '' : (years === 1 ? `${years} year` : `${years} years`);
            const monthStr = months === 0 ? '' : (months === 1 ? `${months} month` : `${months} months`);
            avgAppAgeStr = `${yearsStr} ${monthStr}`;
          }

          this.keywordsAverageMetrics = {
            avg_age_in_days: avgAppAgeStr,
            avg_price: keywordsAverageMetrics?.avg_price ?? 0,
            avg_rating: keywordsAverageMetrics?.avg_rating ? +keywordsAverageMetrics?.avg_rating.toFixed(1) : 0,
            avg_votes: keywordsAverageMetrics?.avg_votes ? +keywordsAverageMetrics?.avg_votes?.toFixed(0) : 0,
            competitors: keywordsAverageMetrics?.competitors ?? 0,
            free_percent: keywordsAverageMetrics?.free_percent?.toFixed(0) ?? 0,
          };
        }
      }
    },
    async fetchLiveSearchData() {
      const url = process.env.VUE_APP_API_URL
          + this.$ApiUrls.liveSearch.KEYWORDS_TOP
          + '?country_code=' + this.currentCountry?.code
          + '&device=' + this.liveSearchDevice?.toLowerCase()
          + '&size=' + 10
          + '&skip=' + this.currentLiveSearchPage
          + '&app_id=' + this.currentApp?.id
          + '&keyword=' + this.currentKeyword?.toLowerCase();

      const result = await httpRequest('GET', url);

      let liveSearchResults = [];
      if (result?.list && result?.list?.length > 0) {
        liveSearchResults = result?.list.map((item, idx) => {
          const categories = item?.data?.categories?.join(', ');
          return {
            type: item?.type,
            is_paid: item.is_paid,
            data: item?.data,
            rating: item?.data?.rating ? item?.data.rating.toFixed(1) : 0,
            categories: categories,
            isAdded: this.myAppsExtractedIds.includes(item.data?.id),
          };
        });
      }

      this.keywordCounts.total_apps_from_live = result.total;
      this.keywordCounts.app_rank_from_live = result.app_rank;

      return {results: liveSearchResults, hasNextPage: result?.hasNexPage, querySugg: result?.query_sugg};
    },
    async fillLiveSearchArrays() {
      const liveSearchResultsObj = await this.fetchLiveSearchData();
      const hasNextPage = liveSearchResultsObj?.hasNextPage;

      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_RESULTS', liveSearchResultsObj);
      await competitorState.setActiveCompetitors();

      this.liveSearchResultsSecondary = [];
      this.showLoadMoreBtn = hasNextPage;
      this.querySugg = liveSearchResultsObj?.querySugg;
    },
    async fetchCompetitors() {
      await competitorState.fetchCompetitors();
    },
    async fetchMyApps() {
      this.searchResultsLoading = true;
      const myApps = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW
      );

      if (myApps) {
        this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(myApps) ?? []);
        this.extractAppsIds();
      }
      this.searchResultsLoading = false;
    },
    async fetchSuggestions() {
      const currentAppId = getCookie('currentAppId');
      if (!currentAppId || currentAppId === 'undefined') {
        return;
      }

      const suggestions = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL
          + this.$ApiUrls.keywordsTracking.SUGGESTED_KEYWORDS
          + '?country_code=' + this.currentCountry?.code
          + '&application_id=' + currentAppId
          + '&keyword=' + this.currentKeyword?.toLowerCase()
      );

      if (suggestions) {
        const suggestionData = suggestions?.suggestion ?? null;
        this.$store.dispatch('keywordsTracking/SET_SUGGESTED_KEYWORDS', suggestionData);
        this.autoSuggestedKeywords = this.suggestedKeywords.map(suggestion => {
          return {
            ...suggestion,
            frozen: true,
          };
        });
        this.autoSuggestedKeywords = this.autoSuggestedKeywords.slice(0, 7);
      }
    },
    async loadMoreClickHandler(event) {
      this.showLoadMorePreloader = true;
      const diff = event.pageY - event.clientY;
      window.scrollTo(0, diff);
      this.currentLiveSearchPage += 10;
      const liveSearchResultsSecondaryObj = await this.fetchLiveSearchData();
      this.liveSearchResultsSecondary = liveSearchResultsSecondaryObj?.results;
      this.showLoadMoreBtn = liveSearchResultsSecondaryObj?.hasNextPage;

      const filteredArr = [...this.liveSearchResults.results, ...this.liveSearchResultsSecondary];

      const defaultHeaderRowHeight = 60;
      const defaultRowHeight = 64;
      const summaryHeight = defaultRowHeight * filteredArr?.length + defaultHeaderRowHeight;
      const customDataTable = document.querySelector('.custom-data-table');
      customDataTable.style.minHeight = summaryHeight + 'px';

      liveSearchResultsSecondaryObj.results = filteredArr;
      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_RESULTS', liveSearchResultsSecondaryObj);
      await competitorState.setActiveCompetitors();

      window.scrollTo(0, diff);
      this.showLoadMorePreloader = false;
    },
    tableHeaderItem(itemName) {
      return this.tableColumns.find(item => item.value === itemName);
    },
    tableClassCombined(tableItem) {
      return [tableItem.align, tableItem.headingClass]
    },
    tableStyleCombined(tableItem) {
      return {width: tableItem.textWidth};
    },
    constructAppType(type) {
      const TYPES = {
        'apps': 'apps',
        'editorial-items': 'STORY',
        'app-bundles': 'BUNDLE',
        'developers': 'DEVELOPER PAGE',
        'in-apps': 'In-Apps Purchase',
        'preorder': 'PRE-ORDER',
        'subscription': 'SUBSCRIPTION',
      };

      return TYPES[type];
    },
    appClickHandler(obj) {
      this.showAppDetailsModal = true;
      this.clickedItem = {
        ...obj.data,
        store: obj?.data.store,
      };
    },
    async modalCompetitorChanged(obj) {
      const {type, id, competitorType} = obj;
      await this.competitorChanged(type, id, competitorType);

    },
    cropImg(imageUrl, size, store) {
      return cropImage(imageUrl, size, store);
    },
    async translate() {
      let phrases = this.liveSearchResults
        .filter(item => item.translate === undefined || item.translate === null)
        .map(item => item.title);
      if (phrases.length > 0) {
        let translations = await translates.translatePhrases(this.currentApp?.store?.key, phrases);
        this.liveSearchResults.forEach((item) => {
          item.translate = translations[item.title] ?? item.translate;
        });
      }
    },
    async translateClickHandler() {
      if (this.translateIsActive) {
        this.liveSearchResults.forEach((item) => {
          item.translate = null;
        });
        this.translateIsActive = false;
        return;
      }

      await this.translate();
      this.translateIsActive = true;
    },
  },
  computed: {
    ...mapGetters([
      'myApps',
      'isMobile',
      'pageMeta',
      'currentApp',
      'promiseSettledData',
      'currentCountry',
      'currentCountryManual',
    ]),
    ...mapGetters('keywordsTracking', [
      'suggestedKeywords',
      'sortingMeta',
      'urlQueryString',
      'liveSearchResults',
      'popularityChartResults',
    ]),
    popularityChartResultsLength() {
      return Object.values(this.popularityChartResults)?.length;
    },
    totalAppsCount() {
      if (+this.keywordCounts.total_apps === 50) {
        return '50+';
      } else {
        return this.keywordCounts.total_apps;
      }
    }
  },
  watch: {
    liveSearchResults(newVal, oldVal) {
      if (this.liveSearchResults.length === 0 || !this.translateIsActive) {
        return;
      }

      let newValResults = newVal?.results;
      let oldValResults = oldVal?.results;
      let isIdsEqual = JSON.stringify(newValResults.map(item => item.id)) === JSON.stringify(oldValResults.map(item => item.id));
      if (!isIdsEqual) {
        this.translate();
      }
    },
    async $route(to, from) {
      this.title = to?.meta?.pageTitle ?? '';
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {

        if (getCookie('liveSearchInput')) {
          this.currentKeyword = getCookie('liveSearchInput');
          this.liveSearchResults = [];
          await this.resetData();
          this.initialLoad = false;
        }
      }
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id && oldVal?.id) {
        this.liveSearchDevice = newVal?.store?.key === 'APP_STORE' ? 'iPhone' : 'mobile';
        if (getCookie('liveSearchInput')) {
          this.currentKeyword = '';
          this.showFirstScreen = true;
          this.liveSearchResults = [];
          this.currentLiveSearchPage = 0;
          deleteCookie('liveSearchInput');
        }
      }
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>