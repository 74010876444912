export const liveSearchTableConfig = {
    headers: [
        {
            text: '',
            sortable: false,
            value: 'position',
            width: '60px',
            align: 'right',
        },
        {
            text: 'App',
            sortable: false,
            value: 'title',
            sortBy: 'title',
            align: 'left',
            width: '31%',
            textWidth: '310px',
            headingClass: 'app-column'
        },
        {
            text: 'Competitor',
            value: 'tools',
            width: '14%',
            align: 'left',
            largePadding: true,
            sortable: false,
        },
        {
            text: 'Publisher',
            sortable: false,
            value: 'developer',
            sortBy: 'developer',
            align: 'left',
            width: '20%',
            textWidth: '180px',
        },
        {
            text: 'Category',
            value: 'categories',
            sortBy: 'categories',
            width: '15%',
            align: 'left',
            sortable: false,
            textWidth: '200px',
        },
        {
            text: 'Rating',
            value: 'rating',
            sortBy: 'rating',
            width: '10%',
            align: 'right',
            sortable: false,
        },
        {
            text: 'Voted',
            value: 'votes',
            sortBy: 'votes',
            width: '12%',
            align: 'right',
            sortable: false,
        },
    ],
    dummyTableData: [
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
        {
            categories: 'category',
            developer: "developer",
            id: 11111111,
            logo: null,
            rating: 0.0,
            title: "title",
            votes: 11111111,
        },
    ]
}